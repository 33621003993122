// Charge la base commune
// Styles librairies
import 'owl.carousel/dist/assets/owl.carousel.min.css'

// Styles custom
// import './sass/pages/search.scss'

// Scripts librairies
import 'owl.carousel'

require('./common.js')

// Pages
// async function getHomePage() {
//  pageLoading()
//  const { default: _ } = await import('lodash')
//  import('./js/pages/home.js').then(module => {
//    pageLoading(false)
//  })
// }

// actions principales communes à toutes les pages
$(document).ready(function () {
  // gestion des carousel
  $('div.owl-carousel').each(function () {
    const slider = $(this)
    const options = (typeof $(this).attr('data-plugin-options') !== typeof undefined && $(this).attr('data-plugin-options') !== false) ? $(this).attr('data-plugin-options').replace(/'/g, '"') : { }
    const defaults = {
      items: 1,
      lazyLoad: true,
      autoplay: true,
      autoplayTimeout: 4000,
      margin: 30,
      autoplayHoverPause: true,
      loop: true
    }
    if (options.length > 0) {
      const obj = JSON.parse(options)
      const config = $.extend({}, defaults, obj)
      slider.owlCarousel(config)
    } else {
      slider.owlCarousel(defaults)
    }
  })
})
