/**
 * Ajuste le header en le fixant à la navigation du site
 */
function adjustHeader() {
  var scroll = $(window).scrollTop()
  if ( scroll >= 155 ) {
    $("body.fixed-header").addClass("smallHeader")
    if ( scroll >= 160 ) {
      $("body.fixed-header").addClass("active")
    }
  } else {
    $("body.fixed-header").removeClass("smallHeader active")
  }
}

/**
 * Affiche le loader de la page
 */
var _loading = null
export function pageLoading(i = true) {
  if ( i ) {
    $('.preloading').animate({opacity: 1}, 200).show()
    $('body').css({'overflow':'hidden'})
  } else {
    $('.preloading').animate({opacity: 0}, 200, function() { $(this).hide() })
    $('body').css({'overflow':'visible'})
  }
}

/**
 * Contrôle la présence du cookie pour l'affichage des boutons raccourcis depuis le mobile
 * @returns {number} 1 (true) ou 0
 */
function checkMobileShortcuts() {
  var name = 'cookie_shorts_ef=',
      t = document.cookie.split(';')
  for (var i=0; i<t.length; i++) {
    var c = t[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if ( c.indexOf(name) != -1 ) var check_cookie = c.substring(name.length, c.length)
  }
  return check_cookie
}

(function ($) {
  "use strict"

  /* ------------------------------------------------------------------------
     LOADER
   ------------------------------------------------------------------------  */
  $(window).on('load', function() {
    pageLoading(false)
    adjustHeader()

    var $s = $('#ef-shorts')
    if ( checkMobileShortcuts() == 1 ) {
      $s.removeClass('show').addClass('hide')
      $s.find('.toggler i').removeClass('fa-angle-double-down').addClass('fa-angle-double-up')
    }
  })

  /* ------------------------------------------------------------------------
     SMALL HEADER
  ------------------------------------------------------------------------ */
  $(window).scroll(function() {
    adjustHeader()
  })

  /* ------------------------------------------------------------------------
     MOBILE MENU
  ------------------------------------------------------------------------ */
  $(".main-nav li a").on("click", function() {
    $(this).parent("li").find(".dropdown-menu").slideToggle()
    $(this).find("i").toggleClass("fa-caret-down fa-caret-up")
  })
//  $("#review-form-close").on("click", function() {
//    $("#add-review-form").slideUp()
//  })

  /* ------------------------------------------------------------------------
     SMOOTH SCROLLING
  ------------------------------------------------------------------------ */
  $('.scroll').each( function() {
    var $this = $(this)
    $(this).on('click', function(e) {
      var t = ($(e.target).is('a')) ? this.hash : $(this).attr('target')
      e.preventDefault()
      if ( $this.length > 0 ) {
         $('html, body').animate({
          scrollTop: ($(t).offset().top - 150) - -1
        }, 500)
      }
    })
  })

  /* ------------------------------------------------------------------------
     TOGGLES ICON
  ------------------------------------------------------------------------ */
  $('.toggle-heading').on('click', function() {
    $(this).find('i').toggleClass('fa-minus fa-plus')
  })

  /* ------------------------------------------------------------------------
     SEARCH
  ------------------------------------------------------------------------ */
//  $('.search-trigger').on('click', function() {
//    $('.search-container').fadeIn()
//  })
//  $('.header-search-close').on('click', function() {
//    $('.search-container').fadeOut()
//  })

  /* ------------------------------------------------------------------------
     MOBILE SHORTCUTS
  ------------------------------------------------------------------------ */
  var $s = $('#ef-shorts')
  $s.find('.toggler').on('click', function() {
    $s.toggleClass('hide show')
    $s.find('.toggler i').toggleClass('fa-angle-double-up fa-angle-double-down')
    if ( $s.hasClass('show') )
      document.cookie = 'cookie_shorts_ef=0; expires=Fri, 31 Dec 2999 23:59:59 GMT; path=/'
    else
      document.cookie = 'cookie_shorts_ef=1; expires=Fri, 31 Dec 2999 23:59:59 GMT; path=/'
  })

  /* ------------------------------------------------------------------------
    ANIMATIONS
  ------------------------------------------------------------------------ */
//  $('.animate-it').appear()
//  $(document.body).on('appear', '.animate-it', function(e, $affected) {
//    var fadeDelayAttr,
//        fadeDelay
//    $(this).each(function() {
//      if ($(this).data('delay')) {
//        fadeDelayAttr = $(this).data('delay')
//        fadeDelay = fadeDelayAttr
//      } else {
//        fadeDelay = 0
//      }
//      $(this).delay(fadeDelay).queue(function() {
//        $(this).addClass('animated').clearQueue()
//      })
//    })
//  })

})(jQuery)
