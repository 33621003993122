/* fichier JS de base commun quelques soient les versions du projet web */

// charge bootstrap nécessite jquery et popper
import 'bootstrap/js/dist/collapse' // directement déclaré dans le fichier de config webpack base
import 'bootstrap/js/dist/dropdown' // directement déclaré dans le fichier de config webpack base
import 'bootstrap/js/dist/util' // directement déclaré dans le fichier de config webpack base
// import 'bootstrap' // directement déclaré dans le fichier de config webpack base
/* global lazySizes */
import 'lazysizes'

// pas de ./ car on résout dans theme donc déjà déclaré dans resolve.modules dans webpack.base.js
import './scss/_bootstrap_custom'

// Styles template
import 'css/advisor.scss'
import 'css/color-orange'

// Styles librairies
import './js/libs/jquery-nice-select/scss/nice-select'

// Styles custom
import './scss/custom'

// Plugins template
import './js/libs/jquery-nice-select/js/jquery.nice-select.min.js'

// Scripts librairies
// import './js/libs/jquery.appear/jquery.appear.js'
import { pageLoading } from 'js/custom_script'
import { extendClick } from './js/libs/utils'
import '!./js/components/cookie.js' // lazy loading https://github.com/aFarkas/lazysizes
lazySizes.cfg.expand = 80
lazySizes.cfg.expFactor = 1

// lazysizes : add simple support for background images :
document.addEventListener('lazybeforeunveil', function (e) {
  const bg = e.target.getAttribute('data-bg')
  if (bg) e.target.style.backgroundImage = 'url(' + bg + ')'
})

// Components
function getSearchAgency () {
  pageLoading()
  import('./js/components/agency-search-form.js').then(() => {
    pageLoading(false)
  })
}

// Utils
async function getScrollToTop () {
  const { default: _ } = await import('lodash')
  import('./js/libs/scrollToTop/scrollToTop.js').then(() => {
    $.scrollUp({
      easingType: 'linear',
      topSpeed: 300,
      scrollSpeed: 400,
      animation: 'fade',
      scrollText: '<i class="fa fa-angle-up fa-2x"></i>'
    })
  })
}

// ajout au 05-08-2019 demande dynamisme sur page d'accueil
let dk = false
function autoSelectDuration () {
  setTimeout(function () {
    if (!dk) {
      const $f = $('form.duration-rate')
      const i = $f.find('select.duration').find('option:selected')
      i.removeAttr('selected')
      i.next().attr('selected', true)
      $f.find('select.rate').val($f.find('select.duration').val())
      $f.find('select.nice').niceSelect('update')
      autoSelectDuration()
    }
  }, 5000)
}

// Actions principales communes à toutes les pages
$(document).ready(function () {
  $('select.nice').niceSelect()

  const $f = $('form.duration-rate')
  $f.find('select').on('change', function () {
    $f.find('select').val($(this).val())
    $f.find('select.nice').niceSelect('update')
    dk = true
  })
  autoSelectDuration()

  // async components
  $('form[name="agency_search_form"] input[name="search"]').on('click', function () {
    $(this).blur().attr('disabled', '')
    getSearchAgency()
    $(this).off('click')
  })

  // utils
  $(window).scroll(function () {
    if ($(window).scrollTop() >= 160) {
      if (!$('#scrollUp').length) getScrollToTop()
    }
  })

  // formulaires de recherche
  $('form[name="search_form"]').on('submit', function () {
    if ($(this).find('input[name="field"]').val().replace(/\s/g, '').length) { // le champ de recherche doit être différent que des espaces / tabs / breaklines etc.
      $(this).attr('action', $(this).attr('action') + '/' + ($(this).find('input[name="field"]').val().replace(/ /g, '-') + '').toLowerCase())
    }
  })

  // boutons de partage
  $('button[type="button"][data-toggle="share"]').on('click', function () {
    window.open($(this).attr('data-href'))
  })

  extendClick()
})
